import { Component } from '@angular/core';
import { ControlService } from './services/control.service';
import { Config } from '../config/config';
import { ApiService } from './services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(
    public controlService: ControlService,
    private apiService: ApiService
  ) {

  }

  getInitialSettings() {

    const data = {
      default_device: Config.APP_DEVICE_ID
    };

    return this.apiService.callWazzupV2Service(Config.INITIAL_SETTINGS_URL, data);
  }
}
