import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { random } from 'lodash';
import { Config } from 'src/config/config';
import { Md5 } from 'ts-md5';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  getApiTokenKeyPair() {

    let now = Date.now();
    var token;
    var key;
    if (now) {

      key = now.toString();
    } else {

      key = random(100000000, 999999999).toString();
    }
    token = Md5.hashStr(Config.API_KEY+key);
    return [key, token];
  }

  callWazzupV2Service(apiUrl: string, rawBody) {

    var uri = apiUrl.replace(Config.DOMAIN_URL, '');
    const httpOptions = {
      headers: new HttpHeaders({
        "authorization": 'Digest username="' + Config.API_AUTH_USERNAME + '", realm="' + Config.API_AUTH_REALM + '", nonce="5b51bf3a911d8", uri="' + uri + '", qop=auth, nc=00000009, cnonce="a6f5acf57475e6a6", response="25c3438312279b190da5593cd658aaaf", opaque="ad85287bbf9e700d6817070cd3216cdf"',
        "content-type": 'application/json'
      })
    };

    return this.httpClient.post(apiUrl, rawBody, httpOptions);
  }


  callWazzupApi(apiUrl: string, params) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization': 'Basic d2F6enVwbGl2ZWFwcDphNGJmOGFkNjU5M2U2NTExOWFhOTdlZDFjMWI4MTRiOA=='
      })
    };

    return this.httpClient.post(apiUrl, params, httpOptions);
  }
}
