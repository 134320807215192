import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.css']
})
export class BrandsComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    this.loadStylesheet("assets/site/css/brands/new/bootstrap.css");
    this.loadStylesheet("assets/site/css/brands/new/templatemo-style.css");
  }

  public loadStylesheet(url: string) {

    if (url) {

      const head = <HTMLHeadElement>document.head;
      const styles = document.createElement('link');
      styles.rel = 'stylesheet';
      styles.type = 'text/css';
      styles.media = 'screen';
      styles.href = url;
      head.appendChild(styles);
    }
  }

}
