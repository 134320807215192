import { Component, OnInit } from '@angular/core';
import { ControlService } from '../../services/control.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../../config/config';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { ApiService } from 'src/app/services/api.service';

declare var $: any;
@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.css']
})
export class ResourceComponent implements OnInit {

  resources: any = [];
  private resource_keys: any = [];
  channelTitle;
  private channel_id;
  private playlistID;
  playlistTitle;
  private resourceData: any = {};
  private responseResourceData: any = [];
  private responseResourceFilterData: any = [];
  private apiOrderItem = Config.ORDER_ITEM;
  private responseOrderData: any = [];
  private resource_sort_order: string = "0";
  private verify_url;
  private tags = "";
  private count: number = 0;
  private filter_channel_id;
  private filter_playlist_id;
  private url_type;
  private search_key = "";
  private filter_resources: any = [];


  constructor(
    public controlService: ControlService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private router: Router,
    private toastr: ToastrService
  ) {

    this.verify_url = this.activatedRoute.snapshot.url[0].path;
    this.activeComponent();
    if (this.verify_url == "resources") {

      this.getChannelDetailsFromResults();
      this.getResouceFilterData();
      this.url_type = this.controlService.componentTitle;
    } else {
      this.url_type = "default";
      if (this.controlService.channel_ids == undefined ||
        this.controlService.channelTitle == undefined ||
        this.controlService.playlist_ids == undefined ||
        this.controlService.playlistTitle == undefined) {
        this.getChannelDetailsFromUrl();
      } else {
        this.channelTitle = this.controlService.channelTitle;
        this.channel_id = this.controlService.channel_ids;
        this.playlistID = this.controlService.playlist_ids;
        this.playlistTitle = this.controlService.playlistTitle;
      }
      //checking resouce[] availbity if available in service use that value else make a request
      if (this.controlService.resources == undefined || null) {

        this.getResouceData();
      }
    }

  }
  ngOnInit() {

    if (this.activatedRoute.snapshot.url[5] !== undefined) {
      this.resource_sort_order = this.activatedRoute.snapshot.url[5].path;
    }
    if (this.resource_sort_order == "3") {
      this.resources = _.orderBy(this.controlService.resources, ['title'], ['asc']);
    } else if (this.resource_sort_order == "4") {
      this.resources = _.orderBy(this.controlService.resources, ['title'], ['desc']);
    } else if (this.resource_sort_order == "1") {
      this.resources = _.orderBy(this.controlService.resources, ['created_at'], ['asc']);
    } else if (this.resource_sort_order == "2") {
      this.resources = _.orderBy(this.controlService.resources, ['created_at'], ['desc']);
    } else if (this.resource_sort_order == "5") {
      this.resources = _.orderBy(this.controlService.resources, ['unit_price'], ['asc']);
    } else if (this.resource_sort_order == "6") {
      this.resources = _.orderBy(this.controlService.resources, ['unit_price'], ['desc']);
    } else {
      this.resources = this.controlService.resources;// if there is no data in service 
    }
    if (this.resources !== undefined) {

      this.resource_keys = Object.keys(this.resources);
    }
    this.detailsforBreadcrumbsClick();
  }
  detailsforBreadcrumbsClick() {
    // value inject to service after constructor() since no conflict occur
    this.controlService.channel_ids = this.channel_id;
    this.controlService.channelTitle = this.channelTitle;
    this.controlService.playlistTitle = this.playlistTitle;
    this.controlService.playlist_ids = this.playlistID;
  }
  activeComponent() {

    var snapshot = this.activatedRoute.snapshot;
    this.controlService.componentTitle = snapshot.routeConfig.component.name;
  }
  //channel details from url
  getChannelDetailsFromUrl() {
    this.channel_id = this.activatedRoute.snapshot.url[2].path; // fetching channel id from url
    this.channelTitle = this.activatedRoute.snapshot.url[1].path; // fetch channel title from url
    this.playlistID = this.activatedRoute.snapshot.url[4].path;
    this.playlistTitle = this.activatedRoute.snapshot.url[3].path;
  }
  getChannelDetailsFromResults() {

    this.activatedRoute.queryParams.subscribe(params => {

      console.log("something in queryParams changed 123");
      this.channel_id = (params["channel_ids"] != undefined) ? params["channel_ids"] : 0;
      this.playlistID = (params["playlist_ids"] != undefined) ? params["playlist_ids"] : 0;
      this.channelTitle = (params["channelTitle"] != undefined) ? params["channelTitle"] : "";
      this.playlistTitle = (params["playlistTitle"] != undefined) ? params["playlistTitle"] : "";
      this.tags = (params["filter_items"] != undefined) ? params["filter_items"] : "";
      this.search_key = (params["search_key"] != undefined) ? params["search_key"] : "";
    });

  }
  getResouceData() {

    console.log("Dashboard-home :: getResouceData");
    const body = {
      api_token: Config.API_TOKEN,
      station: this.controlService.getCookieValue(),
      channel_id: this.channel_id,
      playlist_id: this.playlistID
    };
    return this.apiService.callWazzupV2Service(Config.RESOURCES_URL, body)
      .subscribe(data => {

        this.responseResourceData = data
        if (this.responseResourceData == 0 || null || undefined) {

          console.log("No responseResourceData");
        } else {

          this.controlService.resources = this.responseResourceData.data[0].playlist[0].resources;
          // open ngOnit
          this.ngOnInit();
        }
      }
        , err => {
          console.log("errror");
        })
  }
  // when click on resource tile works !! from html
  setResourceDetils(resourcesDetails, resourceTitle, resourceID, resourceType) {

    this.controlService.resourcesDetails = resourcesDetails;
    this.controlService.resourceTitle = resourceTitle;
    this.controlService.resourceID = resourceID;
    this.controlService.resourceType = resourceType;
    this.controlService.resource_results_url = $(location).attr("href");
  }

  addToCart(itemDetails) {

    const body = {
      api_token: Config.API_TOKEN,
      station: this.controlService.getCookieValue(),
      product_id: itemDetails.product_id,
      product_name: itemDetails.product_name,
      mrp: itemDetails.mrp,
      unit_price: itemDetails.unit_price,
      quantity: itemDetails.quantity,
      discount: itemDetails.discount,
      tax: itemDetails.tax,
      tax_class: itemDetails.tax_class,
      hsn: itemDetails.hsn,
      resource_id: itemDetails.resource_id,
      resource_type: itemDetails.resource_type,
      total: itemDetails.unit_price * itemDetails.quantity
    };
    return this.apiService.callWazzupV2Service(this.apiOrderItem, body)
      .subscribe(data => {

        this.responseOrderData = data

        if (this.responseOrderData.status == "success") {

          this.controlService.total_order_cart_items = this.responseOrderData.data.total_items_cart
          document.getElementById('badge').style.display = "inline";
          document.getElementById('badge').innerHTML = this.controlService.total_order_cart_items;
          this.toastr.success(this.responseOrderData.message, this.responseOrderData.status, {
            timeOut: 1000,
            positionClass: 'toast-custom-1',
            progressBar: true,
            progressAnimation: 'increasing'
          });
        }

      }
        , err => {
          console.log("errror");
        })
  }
  showOptionMessage() {
    this.toastr.success("", "Please select options", {
      timeOut: 2000,
      positionClass: 'toast-custom-1',
      progressBar: true,
      progressAnimation: 'increasing'
    });
  }

  getResouceFilterData() {


    this.activatedRoute.queryParams.subscribe(params => {
      this.filter_channel_id = (params["channel_ids"] != undefined) ? params["channel_ids"] : 0;
      this.filter_playlist_id = (params["playlist_ids"] != undefined) ? params["playlist_ids"] : 0;
    });

    const body = {
      api_token: Config.API_TOKEN,
      station: this.controlService.getCookieValue(),
      filter_tags: this.tags,
      channel_id: this.filter_channel_id,
      playlist_id: this.filter_playlist_id,
      search_key: this.search_key
    };

    return this.apiService.callWazzupV2Service(Config.RESOURCES_URL, body)
      .subscribe(data => {

        this.responseResourceFilterData = data;
        if (this.responseResourceFilterData.data == undefined) {
          this.controlService.resources = this.responseResourceFilterData.data;
          document.getElementById('no_results').style.display = "inline-block";
        } else {
          for (let i = 0; i < this.responseResourceFilterData.data.length; i++) {
            for (let j = 0; j < this.responseResourceFilterData.data[i].playlist.length; j++) {
              for (let k = 0; k < this.responseResourceFilterData.data[i].playlist[j].resources.length; k++) {
                this.filter_resources.push(this.responseResourceFilterData.data[i].playlist[j].resources[k]);
              }
            }
          }
          this.controlService.resources = this.filter_resources;
          // open ngOnit
        }
        this.ngOnInit();
      }
        , err => {
          console.log("errror");
        })
  }
}
