import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: [
    '../../../../node_modules/bootstrap/dist/css/bootstrap.css',
    './app-layout.component.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    this.loadScript("https://code.jquery.com/jquery-3.1.1.min.js");
    this.loadScript("https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js");
    this.loadScript("https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.0/umd/popper.min.js");
    this.loadStylesheet("assets/site/css/style.css");
  }

  public loadScript(url: string) {

    if (url) {
      
      const body = <HTMLDivElement>document.body;
      const script = document.createElement('script');
      script.innerHTML = '';
      script.src = url;
      script.async = false;
      script.defer = true;
      body.appendChild(script);
    }
  }
  public loadStylesheet(url: string) {

    if (url) {

      const head = <HTMLHeadElement>document.head;
      const styles = document.createElement('link');
      styles.rel = 'stylesheet';
      styles.type = 'text/css';
      styles.media = 'screen';
      styles.href = url;
      head.appendChild(styles);
    }
  }
}
