import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Config } from '../../config/config';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ControlService {

  public default_dynamic_form;
  public device_id;
  public supported_login;
  public cookieValue;
  public cookieDeviceIdValue;
  public cookieDefaultFormIdValue;
  // public loginStatus;
  public resourceType;
  public channel_ids;
  public channelTitle;
  public playlist_ids;
  public playlistTitle;
  public resourceID;
  public resourceTitle;
  public appDeviceType;

  // playlist and resource array
  public resources;
  public playLists;
  public resourcesDetails;
  //breadcrumbs
  public subBreadcrumbs = "Playlist";
  public sub2Breadcrumbs = "Resource";
  public detailpageBreadcums = "Details";
  //to find current component 
  public componentTitle;
  public pageTitle;
  public channelList;
  public total_order_cart_items;
  public filters;
  public filters_selected_items;
  public filter_open: boolean;
  private header_bar_color;
  private header_title_color;
  public app_device_urls;
  public resource_results_url;

  clearServiceValue() {
    

    this.supported_login = undefined;
    this.cookieValue = undefined;
    this.resourceType = undefined;
    this.channel_ids = undefined;
    this.channelTitle = undefined;
    this.playlist_ids = undefined;
    this.playlistTitle = undefined;
    this.resourceID = undefined;
    this.resourceTitle = undefined;
    this.resources = undefined;
    this.playLists = undefined;
    this.resourcesDetails = undefined;
    this.componentTitle = undefined;
    this.pageTitle = undefined;
    this.channelList = undefined;
    this.default_dynamic_form = undefined;
    this.device_id = undefined;
    this.cookieDeviceIdValue = undefined;
    this.cookieDefaultFormIdValue = undefined;
    this.total_order_cart_items = undefined;
    this.filters = undefined;
    this.filters_selected_items = undefined;
    this.header_bar_color = undefined;
    this.header_title_color = undefined;
    this.app_device_urls = undefined;
    this.resource_results_url = undefined;

    

  }
  clearCookie() {

    this.cookieService.delete(Config.COOKIE_NAME, "/");
    this.cookieService.delete(Config.COOKIE_DEVICE_ID, "/");
    this.cookieService.delete(Config.COOKIE_DYNAMIC_FORM_ID, "/");
    this.cookieService.delete(Config.COOKIE_HEADER_BAR_COLOR, "/");
    this.cookieService.delete(Config.COOKIE_HEADER_TITLE_COLOR, "/");
    localStorage.clear();
    this.router.navigate(['/login'])

  }
  getCookieValue() {
    return this.cookieValue = this.cookieService.get(Config.COOKIE_NAME);
  }
  getCookieDeviceId() {
    return this.cookieDeviceIdValue = this.cookieService.get(Config.COOKIE_DEVICE_ID);
  }
  getCookieDynamicFormId() {
    return this.cookieDefaultFormIdValue = this.cookieService.get(Config.COOKIE_DYNAMIC_FORM_ID);
  }
  getHeaderBarColor(){
    return this.header_bar_color = this.cookieService.get(Config.COOKIE_HEADER_BAR_COLOR);
  }
  getHeaderTitleColor(){
    return this.header_title_color = this.cookieService.get(Config.COOKIE_HEADER_TITLE_COLOR);
  }
  constructor(
    private cookieService: CookieService,
    private router: Router
  ) { }
}