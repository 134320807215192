import { Component, OnInit } from '@angular/core';
import { ControlService } from '../services/control.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../config/config';
import * as $ from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { BroadcastService } from '../services/broadcast.service';
import { ApiService } from '../services/api.service';
import { HttpParams } from '@angular/common/http';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  ordercartItems: any = [];
  ordercartItems_keys: any = [];
  private responseOrderCartRemoveItemData: any = {};
  private responseOrderCartClearItemsData: any = {};
  private totalPrice;
  private responseOrderCartCheckoutItemsData: any = {};
  customerFormUrl;
  chatURL;
  discount = 0;
  grandTotal = 0;
  isPromoCodeDisabled: boolean = false;
  isPromoCodeMessageDisabled: boolean = true;
  promoCodeTitle: string = "";
  private responseOrderData: any = [];
  private item_quantity: any;
  private appDeviceUrls: any = [];
  appDeviceUrls_keys: any = [];
  private order_posting_type;
  promoCodeEnabled = "0";
  promocodeId = 0;
  promoCodeInput: String = "";

  constructor(public controlService: ControlService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private toastr: ToastrService,
    private broadcastService: BroadcastService) {

    this.getOrderItemsTotalData();
    console.log("footer constuctor called");
  }

  ngOnInit() {

    this.customerFormUrl = this.sanitizer.bypassSecurityTrustResourceUrl(Config.CUSTOMER_FORM_URL + "?id=" + this.controlService.getCookieDynamicFormId() + "&station=" + this.controlService.getCookieValue() + "&device_id=" + this.controlService.getCookieDeviceId() + "&app_type=" + Config.APP_TYPE + "&device_type=" + Config.APP_DEVICE_TYPE);//=> to [src] in html
    this.order_posting_type = localStorage.getItem(Config.COOKIE_ORDER_POSTING_TYPE);
    this.promoCodeEnabled = localStorage.getItem(Config.COOKIE_PROMO_CODE_ENABLED);

    if (this.controlService.app_device_urls !== undefined) {

      this.appDeviceUrls = this.controlService.app_device_urls;
      this.appDeviceUrls_keys = Object.keys(this.appDeviceUrls);
    } else {

      this.appDeviceUrls = JSON.parse(localStorage.getItem(Config.COOKIE_APP_DEVICE_URLS));
      this.appDeviceUrls_keys = Object.keys(this.appDeviceUrls);
    }

  }

  // To get Total Items Count

  getOrderItemsTotalData() {

    var promoCodeUsageId = localStorage.getItem(Config.COOKIE_PROMO_CODE_APPLIED) ? localStorage.getItem(Config.COOKIE_PROMO_CODE_APPLIED) : '0';
    const body = {
      api_token: Config.API_TOKEN,
      station: this.controlService.getCookieValue(),
      promocode_usage_id: promoCodeUsageId,

    };
    return this.apiService.callWazzupV2Service(Config.ORDER_CART_ITEMS, body)
      .subscribe(data => {

        var responseData: any = [];
        if (data == 0 || null || undefined) {
          console.log("No responseOrderCartItemsData");
        } else {

          // open ngOnit
          responseData = data;
          if (responseData.data != undefined && responseData.data.items != undefined) { // undefined means cart is empty
            this.controlService.total_order_cart_items = responseData.data.items.length;
            document.getElementById('badge').style.display = "inline";
            document.getElementById('badge').innerHTML = this.controlService.total_order_cart_items;
          } else {
            document.getElementById('badge').style.display = "none";
            this.controlService.total_order_cart_items = 0;

          }
          this.ngOnInit();
        }

      }
        , err => {
          console.log("errror1");
        })
  }


  // To get list of order cart items

  getOrderItemsData() {

    document.getElementById("dropup-content").style.display = "none";
    var promoCodeUsageId = localStorage.getItem(Config.COOKIE_PROMO_CODE_APPLIED) ? localStorage.getItem(Config.COOKIE_PROMO_CODE_APPLIED) : '0';

    const body = {
      api_token: Config.API_TOKEN,
      station: this.controlService.getCookieValue(),
      promocode_usage_id: promoCodeUsageId,
    };
    return this.apiService.callWazzupV2Service(Config.ORDER_CART_ITEMS, body)
      .subscribe(data => {

        var responseData: any = [];
        if (data == 0 || null || undefined) {

          console.log("No responseOrderCartItemsData");
        } else {

          responseData = data;
          if (responseData.data != undefined && responseData.data.items != undefined) { // undefined means cart is empty

            this.ordercartItems = responseData.data.items;
            if (this.ordercartItems !== undefined) {

              this.ordercartItems_keys = Object.keys(this.ordercartItems);
            }
            this.controlService.total_order_cart_items = responseData.data.items.length;
            document.getElementById('badge').style.display = "inline";
            document.getElementById('badge').innerHTML = this.controlService.total_order_cart_items;

            var cartarray_length = responseData.data.items.length;
            // var cartarray_length = responseData.data.length;
            var i: number;
            var total: number = 0;
            for (i = 0; i < cartarray_length; i++) {

              var price: number = responseData.data.items[i].unit_price;
              var quantity: number = responseData.data.items[i].quantity;
              var quantityInt = + quantity;
              var priceInint = +price;
              var pricePerPiece = (priceInint * quantityInt);
              total = total + pricePerPiece;
              this.totalPrice = total;
            }
            this.discount = responseData.data.discount;
            this.grandTotal = responseData.data.grand_total;
            if (responseData.data.coupon_code) {

              this.promoCodeTitle = "Promo Code Applied: " + responseData.data.coupon_code;
              this.isPromoCodeDisabled = true;
              this.isPromoCodeMessageDisabled = false;
            } else {

              this.resetPromoCode();
            }
          } else {

            document.getElementById('badge').style.display = "none";
            this.controlService.total_order_cart_items = 0;
            this.ordercartItems = [];
            this.ordercartItems_keys = [];
            this.resetPromoCode();
          }
          this.ngOnInit();
          $('body').css('overflow', 'hidden');
          document.getElementById('ordercartitems').style.display = "block";
        }

      }
        , err => {
          console.log("errror2");
          // this.router.navigate(['error']);
        })
  }
  // To open order cart items modal
  openCartModal() {
    $('body').css('overflow', 'hidden');
    document.getElementById('ordercartitems').style.display = "block";
  }
  // To close order cart items modal
  closeCartModal() {
    $('body').css('overflow', 'auto');
    document.getElementById('ordercartitems').style.display = "none";
  }

  // Remove one item from cart
  removeItemFromCart(product_id, resource_id, resource_type) {

    const body = {
      api_token: Config.API_TOKEN,
      station: this.controlService.getCookieValue(),
      product_id: product_id,
      resource_id: resource_id,
      resource_type: resource_type,
    };


    return this.apiService.callWazzupV2Service(Config.ORDER_CART_ITEM_REMOVE, body)
      .subscribe(data => {

        this.responseOrderCartRemoveItemData = data;
        if (this.responseOrderCartRemoveItemData.status == Config.RESPONSE_SUCCESS) {

          this.getOrderItemsData();
        }
      }
        , err => {
          console.log("errror3");
        })
  }

  //Clear Items from Cart
  clearOrderCart() {

    const body = {
      api_token: Config.API_TOKEN,
      station: this.controlService.getCookieValue(),
    };

    return this.apiService.callWazzupV2Service(Config.ORDER_CART_ITEMS_CLEAR, body)
      .subscribe(data => {
        this.responseOrderCartClearItemsData = data;
        if (this.responseOrderCartClearItemsData.status == Config.RESPONSE_SUCCESS) {

          this.getOrderItemsData();
        }
      }
        , err => {
          console.log("errror4");
        })
  }

  // Checkout Items

  checkout() {

    var promoCodeUsageId = localStorage.getItem(Config.COOKIE_PROMO_CODE_APPLIED) ? localStorage.getItem(Config.COOKIE_PROMO_CODE_APPLIED) : '0';
    const [key, token] = this.apiService.getApiTokenKeyPair();
    const orderData = {
      "applied_promocode_usage_id": promoCodeUsageId,
      "value_reduction": this.discount,
      "remarks": "",
      "grand_total": this.grandTotal,
      "orders": this.ordercartItems
    }
    let body = new HttpParams();
    body = body.set('token', token);
    body = body.set('key', key);
    body = body.set('station', this.controlService.getCookieValue());
    body = body.set('device_id', this.controlService.getCookieDeviceId());
    body = body.set('app_type', Config.APP_TYPE);
    body = body.set('device_type', Config.APP_DEVICE_TYPE);
    body = body.set('promocode_usage_id', promoCodeUsageId);
    body = body.set('order_json', JSON.stringify(orderData));

    return this.apiService.callWazzupApi(Config.ORDER_CART_CHECKOUT, body)
      .subscribe(data => {

        console.log("Data: ");
        console.log(data);
        var responseData: any = [];
        if (data != 0 && data != null && data != undefined) {

          responseData = data;
          if (responseData.status == Config.RESPONSE_SUCCESS) {

            if (this.order_posting_type == "0") {

              $('body').css('overflow', 'auto');
              document.getElementById('ordercartitems').style.display = "none";
              this.toastr.success(this.responseOrderCartCheckoutItemsData.message, this.responseOrderCartCheckoutItemsData.status, {
                timeOut: 1000,
                positionClass: 'toast-custom-1',
                progressBar: true,
                progressAnimation: 'increasing'
              });
            } else {

              document.getElementById('badge').style.display = "none";
              this.controlService.total_order_cart_items = 0;
              var x = document.getElementById("iframe-customer-form");
              var tmp_src = x.getAttribute("src");
              x.setAttribute("src", tmp_src);
              $('body').css('overflow', 'hidden');
              document.getElementById('ordercartitems').style.display = "none";
              document.getElementById('customer_form').style.display = "block";
            }
            this.resetPromoCode();

          } else {

            var errorMessage = responseData.message ? responseData.message : "Order posting failed";
            this.toastr.error(errorMessage, "error", {
              timeOut: 3000,
              positionClass: 'toast-custom-1',
              progressBar: true,
              progressAnimation: 'increasing'
            });
          }
        } else {

          this.toastr.error("Order posting failed.Please try again.", "error", {
            timeOut: 3000,
            positionClass: 'toast-custom-1',
            progressBar: true,
            progressAnimation: 'increasing'
          });
        }
      }
        , err => {
          console.log("error");
        })
  }

  closeCustomerFormModal() {


    $('body').css('overflow', 'auto');
    document.getElementById('customer_form').style.display = "none";
    this.broadcastService.sendMessage('closeCustomerFormModal');
    localStorage.setItem(Config.COOKIE_REPEAT_PAYMENT_IS_DONE, "0");
  }
  openchat() {

    $('body').css('overflow', 'hidden');
    this.chatURL = this.sanitizer.bypassSecurityTrustResourceUrl(Config.CHAT_ACCESS_URL + "?station=" + this.controlService.getCookieValue() + "&api_token=");
    document.getElementById("dropup-content").style.display = "none";
    document.getElementById('chat_form').style.display = "block";

  }
  closeChat() {
    $('body').css('overflow', 'auto');
    this.chatURL = this.sanitizer.bypassSecurityTrustResourceUrl("");

    document.getElementById('chat_form').style.display = "none";

  }
  // Update quantity of item
  quantity_change(itemDetails, type) {
    if ((type == 'minus') && itemDetails.quantity <= 1) {
      this.toastr.error("Please enter valid quantity", "error", {
        timeOut: 3000,
        positionClass: 'toast-custom-1',
        progressBar: true,
        progressAnimation: 'increasing'
      });
    } else {
      if (type == 'minus') {
        this.item_quantity = '-1';
      } else {
        this.item_quantity = '+1';
      }

      const body = {
        api_token: Config.API_TOKEN,
        station: this.controlService.getCookieValue(),
        product_id: itemDetails.product_id,
        product_name: itemDetails.product_name,
        mrp: itemDetails.mrp,
        unit_price: itemDetails.unit_price,
        quantity: this.item_quantity,
        discount: itemDetails.discount,
        tax: itemDetails.tax,
        tax_class: itemDetails.tax_class,
        hsn: itemDetails.hsn,
        resource_id: itemDetails.resource_id,
        resource_type: itemDetails.resource_type,
        total: itemDetails.unit_price * this.item_quantity
      };

      return this.apiService.callWazzupV2Service(Config.ORDER_ITEM, body)
        .subscribe(data => {

          this.responseOrderData = data
          if (this.responseOrderData.status == Config.RESPONSE_SUCCESS) {
            this.getOrderItemsData();
            this.toastr.success(this.responseOrderData.message, this.responseOrderData.status, {
              timeOut: 1000,
              positionClass: 'toast-custom-1',
              progressBar: true,
              progressAnimation: 'increasing'
            });
          }

        }
          , err => {
            console.log("errror5");
          })
    }
  }

  sortFilter() {
  }

  openDeviceURLList() {

    if (document.getElementById("dropup-content").style.display == "block") {
      document.getElementById("dropup-content").style.display = "none";
      document.getElementById("dropup-content-arrow").style.display = "none";
    } else {
      document.getElementById("dropup-content").style.display = "block"
      document.getElementById("dropup-content-arrow").style.display = "block";

    }
  }

  openDeviceURL(id) {

    $('body').css('overflow', 'hidden');
    this.openDeviceURLList();
    this.customerFormUrl = this.sanitizer.bypassSecurityTrustResourceUrl(Config.CUSTOMER_FORM_URL + "?id=" + id + "&station=" + this.controlService.getCookieValue() + "&device_id=" + this.controlService.getCookieDeviceId() + "&app_type=" + Config.APP_TYPE + "&device_type=" + Config.APP_DEVICE_TYPE);//=> to [src] in html
    var x = document.getElementById("iframe-customer-form");
    var tmp_src = x.getAttribute("src");
    x.setAttribute("src", tmp_src);
    document.getElementById('customer_form').style.display = "block";
  }
  openPaymentOnDeviceModal() {

    $('body').css('overflow', 'hidden');
    document.getElementById('payment_on_device_form').style.display = "block";
    $("#device_amount").html(localStorage.getItem("amount"));
    $("#device_order_id").html(localStorage.getItem("order_id"));
    $("#device_transaction_id").html(localStorage.getItem("transaction_id"));

    $("#merchant_key").val(Config.PAYMENT_MERCHANT_KEY);
    $("#transaction_id").val(localStorage.getItem("transaction_id"));
    $("#amount").val(localStorage.getItem("amount"));
    $("#productinfo").val(localStorage.getItem("productinfo"));
    $("#surl").val(Config.PAYMENT_SUCCESS_URL);
    $("#furl").val(Config.PAYMENT_FAILURE_URL);
    $("#order_id").val(localStorage.getItem("order_id"));
    $("#customer_id").val(localStorage.getItem("customer_id"));
    $("#transaction_type").val(localStorage.getItem("transaction_type"));
    $("#outstanding_payment_id").val(localStorage.getItem("outstanding_payment_id"));
    $("#app_flavor").val(Config.APP_FLAVOR);
    $("#hash").val(localStorage.getItem("hash"));
  }
  onDevicePayNow() {
    $("#paynow_form").submit();
  }
  closePaymentOnDeviceForm() {
    $('body').css('overflow', 'auto');
    document.getElementById('payment_on_device_form').style.display = "none";
  }

  applyPromoCode(promoCode: string) {

    if (promoCode) {


      const [key, token] = this.apiService.getApiTokenKeyPair();
      console.log("Key: " + key + " Token: " + token);
      let body = new HttpParams();
      body = body.set('token', token);
      body = body.set('key', key);
      body = body.set('device_id', this.controlService.getCookieDeviceId());
      body = body.set('station', this.controlService.getCookieValue());
      body = body.set('app_type', Config.APP_TYPE);
      body = body.set('device_type', Config.APP_DEVICE_TYPE);
      body = body.set('grand_total', this.totalPrice);
      body = body.set('promocode', promoCode);

      this.apiService.callWazzupApi(Config.APPLY_PROMO_CODE_URL, body)
        .subscribe(data => {

          this.responseOrderData = data
          console.log(data);
          if (this.responseOrderData.status == Config.RESPONSE_SUCCESS) {

            //TODO: Logic of apply promo code
            this.promocodeId = this.responseOrderData.applied_promocode_usage_id ? this.responseOrderData.applied_promocode_usage_id : 0;
            localStorage.setItem(Config.COOKIE_PROMO_CODE_APPLIED, this.promocodeId.toString());
            this.getOrderItemsData();
            this.promoCodeTitle = 'Promo Code Applying...';
            this.isPromoCodeDisabled = true;
            this.isPromoCodeMessageDisabled = false;
            this.toastr.success(this.responseOrderData.message, this.responseOrderData.status, {

              timeOut: 1000,
              positionClass: 'toast-custom-1',
              progressBar: true,
              progressAnimation: 'increasing'
            });
          } else {

            var errorMessage = this.responseOrderData.message ? this.responseOrderData.message : "Something went wrong !!! Please try again later";
            this.toastr.error(errorMessage, "Error", {
              timeOut: Config.TOAST_MSG_TIMEOUT,
              positionClass: Config.TOAST_MSG_POSITION_CLASS,
              progressBar: true,
              progressAnimation: 'increasing'
            });
          }

        }
          , err => {

            console.log("errror6");
          })
    } else {

      this.toastr.error("Unable to apply. Promo Code is blank.", "Error", {
        timeOut: Config.TOAST_MSG_TIMEOUT,
        positionClass: Config.TOAST_MSG_POSITION_CLASS,
        progressBar: true,
        progressAnimation: 'increasing'
      });
    }
  }

  closePromoCode() {

    this.resetPromoCode();
    this.getOrderItemsData();
  }

  resetPromoCode(){

    this.isPromoCodeMessageDisabled = true;
    this.isPromoCodeDisabled = false;
    localStorage.setItem(Config.COOKIE_PROMO_CODE_APPLIED, '0');
    this.discount = 0;
    this.grandTotal = 0;
    this.promoCodeTitle = '';
    this.promoCodeInput = '';
  }
}
