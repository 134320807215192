import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Config } from '../../config/config';
import { ControlService } from '../services/control.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
 
@Injectable()
export class AuthGuard implements CanActivate {
 
    constructor(
        private router: Router,
        private cookieService:CookieService,
        private controlService: ControlService) { 
      }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.cookieService.get(Config.COOKIE_NAME).length > 0) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;
    }
}