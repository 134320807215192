import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-banner-screen',
  templateUrl: './banner-screen.component.html',
  styleUrls: ['./banner-screen.component.css']
})
export class BannerScreenComponent implements OnInit {

  constructor(
    public router :Router,
    private location :Location
  ) { }

  ngOnInit() {
  setTimeout((router: Router) => {
      this.router.navigate([''], { replaceUrl :true } );
  },2000);  //2s
  }

}
