import { Component, OnInit } from '@angular/core';
import { ControlService } from '../../services/control.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../../config/config';
import * as _ from 'lodash';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.css']
})
export class PlaylistsComponent implements OnInit {
  // declarations
  playLists: any = [];
  private channel_id;
  private responsePlaylistData: any = {};
  channelTitle;
  private playListData: any = {};
  private play_list_sort_order: string = "0";

  // Constructor
  constructor(
    public controlService: ControlService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService
  ) {
    this.activeComponent();

    if (this.controlService.channel_ids == undefined || 0 || null) {

      this.getChannelDetailsFromUrl();
    } else {

      this.channelTitle = this.controlService.channelTitle;
      this.channel_id = this.controlService.channel_ids;
    }
    this.checkPlaylistArray();
  }
  ngOnInit() {

    if (this.activatedRoute.snapshot.url[3] !== undefined) {

      this.play_list_sort_order = this.activatedRoute.snapshot.url[3].path;
    }

    if (this.play_list_sort_order == "3") {
      this.playLists = _.orderBy(this.controlService.playLists, ['title'], ['asc']);
    } else if (this.play_list_sort_order == "4") {
      this.playLists = _.orderBy(this.controlService.playLists, ['title'], ['desc']);
    } else {
      this.playLists = this.controlService.playLists;
    }


    this.controlService.pageTitle = this.controlService.channelTitle;

  }
  activeComponent() {

    var snapshot = this.activatedRoute.snapshot;
    this.controlService.componentTitle = snapshot.routeConfig.component.name;
  }
  getChannelDetailsFromUrl() {

    this.channel_id = this.activatedRoute.snapshot.url[2].path; // fetching channel id from url
    this.channelTitle = this.activatedRoute.snapshot.url[1].path; // fetch channel title from url
    this.controlService.channelTitle = this.channelTitle;
    this.controlService.channel_ids = this.channel_id;
  }
  checkPlaylistArray() {

    if (this.controlService.playLists == null || undefined) {

      this.getPlaylistData();
    } else {

      this.playLists = this.controlService.playLists;

    }
  }
  getPlaylistData() {

    console.log("Dashboard-home :: getPlaylistData");

    const body = {
      api_token: Config.API_TOKEN,
      station: this.controlService.getCookieValue(),
      channel_id: this.channel_id
    };

    return this.apiService.callWazzupV2Service(Config.RESOURCES_URL, body)
      .subscribe(data => {
        this.playListData = data
        this.responsePlaylistData = this.playListData.data;
        if (this.responsePlaylistData == 0) {
          console.log("nodata found :");
        } else {

          this.controlService.playLists = this.responsePlaylistData[0].playlist;

          if (this.controlService.playLists != null) {

            this.ngOnInit();
          }
        }
      }
        , err => {
          console.log(Config.CONNECTION_ERROR_MSG);
        })
  }
  // route call from html (click)
  setResource(resources, playlist_title, playlist_id) {
    this.controlService.playlist_ids = playlist_id;
    this.controlService.playlistTitle = playlist_title;
    this.controlService.resources = resources; //resource not available when page reloads or share url  
  }
}
