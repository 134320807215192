import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ControlService } from '../services/control.service';
import { Config } from '../../config/config';
import * as _ from 'lodash';
import { ApiService } from '../services/api.service';

declare var $: any;
@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.css']
})
export class DashboardHomeComponent implements OnInit {
  // declarations
  private responseData: any = {};
  channelList: any = [];
  private sort_order: string = "0";
  appDeviceType;
  dashboardItemLabel;



  // Constructor
  constructor(

    public controlService: ControlService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService) {
    this.activeComponent();
    this.checkChannelListAvailablity();
    console.log("DashboardHomeComponent::constructor");
  }
  checkChannelListAvailablity() {

    if (this.controlService.channelList == undefined || null) {

      this.getData();
    } else {

      this.channelList = this.controlService.channelList;
    }
  }

  ngOnInit() {

    this.controlService.channel_ids = 0;
    this.controlService.channelTitle = "";
    this.controlService.playlist_ids = 0,
      this.controlService.playlistTitle = "";
    if (this.activatedRoute.snapshot.url[1] !== undefined) {
      this.sort_order = this.activatedRoute.snapshot.url[1].path;
    }

    if (this.controlService.appDeviceType !== undefined) {

      this.appDeviceType = this.controlService.appDeviceType;
    } else {

      this.appDeviceType = localStorage.getItem(Config.COOKIE_APP_DEVICE_TYPE);
    }
    if (this.appDeviceType && this.appDeviceType == 1) {

      this.dashboardItemLabel = Config.CHANNEL_LABEL_ADY_ECOMM;
    } else {

      this.dashboardItemLabel = Config.CHANNEL_LABEL_ADY_DEFAULT;
    }
  }

  getData() {

    console.log("Dashboard-home :: getData");

    const body = {
      api_token: Config.API_TOKEN,
      station: this.controlService.getCookieValue()
    };
    return this.apiService.callWazzupV2Service(Config.RESOURCES_URL, body)
      .subscribe(data => {
        this.responseData = data
        if (this.sort_order == "3") {

          this.controlService.channelList = _.orderBy(this.responseData.data, ['title'], ['asc']);
        } else if (this.sort_order == "4") {

          this.controlService.channelList = _.orderBy(this.responseData.data, ['title'], ['desc']);
        } else {

          this.controlService.channelList = this.responseData.data;
        }
        this.channelList = this.controlService.channelList;
      }
        , err => {
          console.log(Config.CONNECTION_ERROR_MSG);
        })
  }

  activeComponent() {
    var snapshot = this.activatedRoute.snapshot;
    this.controlService.componentTitle = snapshot.routeConfig.component.name;
    this.controlService.pageTitle = Config.CATEGORIES_NAME;
  }
  setPlayList(playLists, channel_ids, channelTitle) {

    this.controlService.channel_ids = channel_ids;
    this.controlService.channelTitle = channelTitle;
    this.controlService.playLists = playLists;
  }
  openCategory() {
    this.router.navigate(['/category']);
  }
}
