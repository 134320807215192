import { Component, OnInit } from '@angular/core';
import { ControlService } from '../../services/control.service'
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../../config/config';
import { DomSanitizer } from '@angular/platform-browser';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryImageSize } from 'ngx-gallery';
import 'hammerjs';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-details-page',
  templateUrl: './details-page.component.html',
  styleUrls: ['./details-page.component.css']
})
export class DetailsPageComponent implements OnInit {

  channelTitle;
  private channel_id;
  private playlistID;
  resourceFileUrl;
  resourceThumbnailUrl;
  resourceFileName;
  resourceFileDecription;
  playlistTitle;
  private resourceType;
  resourceTitle;
  private resourceID;
  secureSourceURL;
  private prop: any = [];

  resourcesDetails: any = {};
  private sub_resources: any = [];
  private sub_resources_keys: any = [];
  private apiUrlResourceDetails = Config.RESOURCE_DETAILS_URL;
  private iframe;
  private apiOrderItem = Config.ORDER_ITEM;
  private responseOrderData: any = [];
  private secureSubResourceDocURL;
  private secureSubResourceWebURL;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  private galleryImagesItems: any = {};
  private row_content_width;
  private row_content_height;




  constructor(
    public controlService: ControlService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService

  ) {
    this.activeComponent();
    //checking channel id and title availbity
    if (this.controlService.channel_ids == undefined ||
      this.controlService.channelTitle == undefined ||
      this.controlService.playlist_ids == undefined ||
      this.controlService.playlistTitle == undefined ||
      this.controlService.resourceID == undefined ||
      this.controlService.resourceType == undefined
    ) {
      this.getChannelDetailsFromUrl();
    } else {
      this.channelTitle = this.controlService.channelTitle;
      this.channel_id = this.controlService.channel_ids;
      this.playlistID = this.controlService.playlist_ids;
      this.playlistTitle = this.controlService.playlistTitle;
      this.resourceID = this.controlService.resourceID;
      this.resourceTitle = this.controlService.resourceTitle;
      this.resourceType = this.controlService.resourceType;
    }
    //checking resouce[] availbity if available in service use that value else make a request 

    if (this.controlService.resourcesDetails == null || undefined) {
      this.getResouceDatas();
    } else {
      this.resourcesDetails = this.controlService.resourcesDetails;
      if (this.resourceType == 'v' || (this.resourceType == 'd') || (this.resourceType == 'i') || (this.resourceType == 'w') || (this.resourceType == 'a')) {
        this.resourceFileUrl = this.controlService.resourcesDetails.file;
        if (this.resourceType == 'd') {
          this.resourceThumbnailUrl = this.controlService.resourcesDetails.thumbnail;
        }
      } else {
        this.resourceThumbnailUrl = this.controlService.resourcesDetails.thumbnail;
      }
      this.resourceFileName = this.controlService.resourcesDetails.title;
      this.resourceFileDecription = this.controlService.resourcesDetails.description;
    }
  }
  ngOnInit() {
    this.galleryOptions = [
      {
        width: "1452px",
        height: "817px",
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
      }]
    this.galleryImages = [
      {
        small: '',
        medium: '',
        big: ''
      }];
    this.resourcesDetails = this.controlService.resourcesDetails;
    //checking type is available or not
    if (this.controlService.resourcesDetails == undefined || null) {

      console.log("no Resource Type Detils");
    } else {
      this.checkResourceType();
      if (this.resourcesDetails.sub_resources !== undefined) {
        this.sub_resources = this.resourcesDetails.sub_resources;
        this.sub_resources_keys = Object.keys(this.sub_resources);
      }
    }
    this.detailsforBreadcrumbsClick();
  }
  checkResourceType() {
    $(".resource_viewer").hide();
    if (this.resourceType == 'v') {
      document.getElementById("video-player").style.display = "inline";
    } else if (this.resourceType == 'd') {
      this.secureSourceURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.resourceFileUrl);//=> to [src] in html
      document.getElementById("pdf-viewer").style.display = "inline";
    } else if (this.resourceType == 'i') {

      this.resourceSettings(false, false);
      var galleryImageJsonObj = [];
      var galleryImageItem = {};
      galleryImageItem["small"] = this.resourceFileUrl;
      galleryImageItem["medium"] = this.resourceFileUrl;
      galleryImageItem["big"] = this.resourceFileUrl;
      galleryImageJsonObj.push(galleryImageItem);
      this.galleryImagesItems = JSON.parse(JSON.stringify(galleryImageJsonObj));
      this.galleryImages = this.galleryImagesItems;

      document.getElementById("img-viewer").style.display = "inline";
    } else if (this.resourceType == 'w') {
      this.secureSourceURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.resourceFileUrl);//=> to [src] in html
      document.getElementById("web-viewer").style.display = "inline";
    } else if (this.resourceType == 'a') {

      this.resourceSettings(true, true);
      let album_images = this.resourceFileUrl;
      let album_images_array = album_images.split(",");
      var galleryImageJsonObj = [];
      album_images_array.forEach(function (value) {
        var galleryImageItem = {};
        galleryImageItem["small"] = Config.IMAGE_PATH + value;
        galleryImageItem["medium"] = Config.IMAGE_PATH + value;
        galleryImageItem["big"] = Config.IMAGE_PATH + value;;
        galleryImageJsonObj.push(galleryImageItem);
      });
      this.galleryImagesItems = JSON.parse(JSON.stringify(galleryImageJsonObj));
      this.galleryImages = this.galleryImagesItems;
      document.getElementById("album-viewer").style.display = "inline";
    } else {
      this.resourceSettings(false, false);
      var galleryImageJsonObj = [];
      var galleryImageItem = {};
      galleryImageItem["small"] = this.resourceThumbnailUrl;
      galleryImageItem["medium"] = this.resourceThumbnailUrl;
      galleryImageItem["big"] = this.resourceThumbnailUrl;
      galleryImageJsonObj.push(galleryImageItem);
      this.galleryImagesItems = JSON.parse(JSON.stringify(galleryImageJsonObj));
      this.galleryImages = this.galleryImagesItems;
      document.getElementById("default-thumbnail-viewer").style.display = "inline";
      document.getElementById("no-resource").style.display = "inline";
    }
  }
  activeComponent() {
    var snapshot = this.activatedRoute.snapshot;
    this.controlService.componentTitle = snapshot.routeConfig.component.name;
  }
  detailsforBreadcrumbsClick() {
    // value inject to service after constructor() since no conflict occur
    this.controlService.channel_ids = this.channel_id;
    this.controlService.channelTitle = this.channelTitle;
    this.controlService.playlistTitle = this.playlistTitle;
    this.controlService.playlist_ids = this.playlistID;
    this.controlService.resourceID = this.resourceID;
    this.controlService.resourceTitle = this.resourceTitle;
  }
  //channel details from url
  getChannelDetailsFromUrl() {
    this.channel_id = this.activatedRoute.snapshot.url[2].path; // fetching channel id from url
    this.channelTitle = this.activatedRoute.snapshot.url[1].path; // fetch channel title from url
    this.playlistID = this.activatedRoute.snapshot.url[4].path;
    this.playlistTitle = this.activatedRoute.snapshot.url[3].path;
    this.resourceID = this.activatedRoute.snapshot.url[6].path;
    this.resourceTitle = this.activatedRoute.snapshot.url[5].path;
    this.resourceType = this.activatedRoute.snapshot.url[7].path;
  }
  getResouceDatas() {
    this.getResouceData();
    // this.getResouce();
  }
  getResouceData() {

    const body = {
      api_token: Config.API_TOKEN,
      station: this.controlService.getCookieValue(),
      channel_id: this.channel_id,
      playlist_id: this.playlistID,
      resource_id: this.resourceID,
      resource_type: this.resourceType
    };
    return this.apiService.callWazzupV2Service(this.apiUrlResourceDetails, body)
      .subscribe(data => {
        this.resourcesDetails = data
        if (this.resourcesDetails == 0 || null || undefined) {
          // alert(Config.CONNECTION_ERROR_MSG);
        } else {
          this.controlService.resourcesDetails = this.resourcesDetails.data;

          this.resourceFileUrl = this.resourcesDetails.data.file;
          this.resourceThumbnailUrl = this.resourcesDetails.data.thumbnail;

          this.resourceFileName = this.resourcesDetails.data.title;
          this.resourceFileDecription = this.resourcesDetails.data.description;

          // open ngOnit
          this.ngOnInit();
        }
      }
        , err => {
          console.log("errror");
        });
  }
  initAddToCart(itemDetails) {

    const body = {
      api_token: Config.API_TOKEN,
      station: this.controlService.getCookieValue(),
      product_id: itemDetails.product_id,
      product_name: itemDetails.product_name,
      mrp: itemDetails.mrp,
      unit_price: itemDetails.unit_price,
      quantity: itemDetails.quantity,
      discount: itemDetails.discount,
      tax: itemDetails.tax,
      tax_class: itemDetails.tax_class,
      hsn: itemDetails.hsn,
      resource_id: itemDetails.resource_id,
      resource_type: itemDetails.resource_type,
      total: itemDetails.unit_price * itemDetails.quantity

    };
    return this.apiService.callWazzupV2Service(this.apiOrderItem, body)
      .subscribe(data => {

        this.responseOrderData = data
        if (this.responseOrderData.status == "success") {
          this.controlService.total_order_cart_items = this.responseOrderData.data.total_items_cart
          document.getElementById('badge').style.display = "inline";
          document.getElementById('badge').innerHTML = this.controlService.total_order_cart_items;
          this.toastr.success(this.responseOrderData.message, this.responseOrderData.status, {
            timeOut: 1000,
            positionClass: 'toast-custom-1',
            progressBar: true,
            progressAnimation: 'increasing'
          });
        }
      }
        , err => {
          console.log("errror");
        })
  }
  addTocartSubResource() {
    const subresource = (<HTMLInputElement>document.getElementById("select_subresource_id")).value;
    if (subresource !== null && subresource != "") {
      this.initAddToCart(JSON.parse(subresource));
    } else {
      this.toastr.error("Please Select Required Fields", "error", {
        timeOut: 3000,
        positionClass: 'toast-custom-1',
        progressBar: true,
        progressAnimation: 'increasing'
      });
    }
  }

  addTocartSubResourceBycart(subresource) {
    if (subresource !== null && subresource != undefined) {
      this.initAddToCart(subresource);
    } else {
      this.toastr.error("Please Select Required Fields", "error", {
        timeOut: 3000,
        positionClass: 'toast-custom-1',
        progressBar: true,
        progressAnimation: 'increasing'
      });
    }
  }
  reload_resource() {
    location.reload();
  }
  showPdf() {
    $('body').css('overflow', 'hidden');
    document.getElementById('pdf-viewer-modal').style.display = "block";
  }
  closePdf() {
    $('body').css('overflow', 'auto');
    document.getElementById('pdf-viewer-modal').style.display = "none";
  }
  showSubresourceModal(resource_id, resource_file, resource_type) {
    $('body').css('overflow', 'hidden');
    if (resource_type == "d") {
      this.secureSubResourceDocURL = this.sanitizer.bypassSecurityTrustResourceUrl(resource_file);//=> to [src] in html
    } else if (resource_type == "w") {
      this.secureSubResourceWebURL = this.sanitizer.bypassSecurityTrustResourceUrl(resource_file);//=> to [src] in html
    }
    document.getElementById('sub_resource_modal_' + resource_id).style.display = "block";
  }
  closeSubresourceModal(resource_id, resource_type) {
    $('body').css('overflow', 'auto');
    if (resource_type == "v") {
      var vid = <HTMLVideoElement>document.getElementById("singleVideo-player_" + resource_id);
      vid.pause();
    }

    this.secureSubResourceDocURL = "";
    this.secureSubResourceWebURL = "";
    document.getElementById('sub_resource_modal_' + resource_id).style.display = "none";
  }

  resourceSettings(thumb_display, image_Arrows) {
    this.galleryOptions = [
      {
        width: "100%",
        height: "817px",
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        previewZoom: true,
        zoomInIcon: 'fa fa-search-plus',
        zoomOutIcon: 'fa fa-search-minus',
        thumbnailSize: NgxGalleryImageSize.Contain,
        imageSize: NgxGalleryImageSize.Contain,
        imagePercent: 90,
        thumbnailsPercent: 10,
        thumbnailsMargin: 2,
        thumbnailMargin: 2,
        thumbnails: thumb_display,
        imageArrows: image_Arrows


      }
      ,
      {
        breakpoint: 2000,
        width: '100%',
        height: '743px',
        imagePercent: 90,
        thumbnailsPercent: 10,
        thumbnailsMargin: 2,
        thumbnailMargin: 2,
        thumbnailsColumns: 8,
      },
      {
        breakpoint: 1900,
        width: '100%',
        height: '705px',
        imagePercent: 90,
        thumbnailsPercent: 10,
        thumbnailsMargin: 2,
        thumbnailMargin: 2,
        thumbnailsColumns: 8,
      },
      {
        breakpoint: 1800,
        width: '100%',
        height: '668px',
        imagePercent: 90,
        thumbnailsPercent: 10,
        thumbnailsMargin: 2,
        thumbnailMargin: 2,
        thumbnailsColumns: 8,
      },
      {
        breakpoint: 1700,
        width: '100%',
        height: '632px',
        imagePercent: 90,
        thumbnailsPercent: 10,
        thumbnailsMargin: 2,
        thumbnailMargin: 2,
        thumbnailsColumns: 7,
      },
      {
        breakpoint: 1600,
        width: '100%',
        height: '594px',
        imagePercent: 90,
        thumbnailsPercent: 10,
        thumbnailsMargin: 2,
        thumbnailMargin: 2,
        thumbnailsColumns: 6,
      },
      {
        breakpoint: 1500,
        width: '100%',
        height: '556px',
        imagePercent: 90,
        thumbnailsPercent: 10,
        thumbnailsMargin: 2,
        thumbnailMargin: 2,
        thumbnailsColumns: 6,
      },
      {
        breakpoint: 1400,
        width: '100%',
        height: '519px',
        imagePercent: 90,
        thumbnailsPercent: 10,
        thumbnailsMargin: 2,
        thumbnailMargin: 2,
        thumbnailsColumns: 9,
      },
      {
        breakpoint: 1280,
        width: '100%',
        height: '474px',
        imagePercent: 90,
        thumbnailsPercent: 10,
        thumbnailsMargin: 2,
        thumbnailMargin: 2,
        thumbnailsColumns: 8,
      }, {
        breakpoint: 1100,
        width: '100%',
        height: '408px',
        imagePercent: 90,
        thumbnailsPercent: 10,
        thumbnailsMargin: 2,
        thumbnailMargin: 2,
        thumbnailsColumns: 8,
      },
      {
        breakpoint: 1024,
        width: '100%',
        height: '576px',
        imagePercent: 90,
        thumbnailsPercent: 10,
        thumbnailsMargin: 2,
        thumbnailMargin: 2,
        thumbnailsColumns: 6,
      }, {
        breakpoint: 900,
        width: '100%',
        height: '506px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsColumns: 5,
      },
      {
        breakpoint: 800,
        width: '100%',
        height: '450px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsColumns: 5,
      },
      {
        breakpoint: 768,
        width: '100%',
        height: '432px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsColumns: 5,
      },
      {
        breakpoint: 700,
        width: '100%',
        height: '393px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsColumns: 5,
      },
      {
        breakpoint: 600,
        width: '100%',
        height: '337px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 2,
        thumbnailMargin: 2,
        thumbnailsColumns: 4,
      },
      {
        breakpoint: 500,
        width: '100%',
        height: '281px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 2,
        thumbnailMargin: 2,
        thumbnailsColumns: 4,
      },
      {
        breakpoint: 400,
        width: '100%',
        height: '225px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 2,
        thumbnailMargin: 2,
        thumbnailsColumns: 4,
      },
      {
        breakpoint: 300,
        width: '100%',
        height: '169px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 2,
        thumbnailMargin: 2,
        thumbnailsColumns: 4,
      }
    ];

  }
}