export class Config {

    public static VERSION = '4.6';
    public static DOMAIN_URL = 'https://www.wazzup.co/';
    public static SERVICE_URL = Config.DOMAIN_URL+'service/index.php/';
    public static BASE_URL = Config.DOMAIN_URL+'service/v2/index.php/api/';
    public static IMAGE_PATH = Config.DOMAIN_URL+'admin/web/uploads/';
    public static RESOURCES_URL = Config.BASE_URL + 'media/resources';
    public static RESOURCE_DETAILS_URL = Config.BASE_URL + 'media/details';
    public static INITIAL_SETTINGS_URL = Config.BASE_URL + 'settings/initial_settings';

    public static API_AUTH_USERNAME = "admin";
    public static API_AUTH_REALM = "Wazzup Web Service API";
    public static API_TOKEN = "anlh1koj2pak2m8004v7shc3oqrsunjg";
    public static API_KEY = "c8219bba5441b89ee35f2629297ff932";

    public static CONNECTION_ERROR_MSG = "Connection Error!";
    public static COOKIE_NAME = "wazzupCookie";
    public static COOKIE_DEVICE_ID = "wazzupDeviceId";
    public static COOKIE_DYNAMIC_FORM_ID = "wazzupDynamicFormId";
    public static COOKIE_HEADER_BAR_COLOR = "wazzupheader_bar_color";
    public static COOKIE_HEADER_TITLE_COLOR = "wazzupheader_title_color";
    public static COOKIE_APP_DEVICE_URLS = "app_device_urls";
    public static COOKIE_HOME_PAGE_ENABLED = "home_page_enabled";
    public static COOKIE_HOME_PAGE_LOGO = "logo";
    public static COOKIE_HOME_PAGE_BANNER = "banner";
    public static COOKIE_HOME_PAGE_BANNER_VIDEO = "banner_video";
    public static COOKIE_ORDER_PAYMENT_TYPE = "payment_type";
    public static COOKIE_ORDER_POSTING_TYPE = "order_posting_type";
    public static COOKIE_HOME_PAGE_PLAYER_RESOLUTION = "player_resolution";
    public static COOKIE_REPEAT_PAYMENT_IS_DONE = "repeat_payment_is_done";
    public static COOKIE_APP_DEVICE_TYPE = "app_device_type";
    public static COOKIE_PROMO_CODE_ENABLED = "promo_code_enabled";
    public static COOKIE_PROMO_CODE_APPLIED = "promo_code_applied";

    public static CATEGORIES_NAME = 'CATEGORIES';
    public static REQUEST_OTP = Config.BASE_URL + 'users/request_otp';
    public static VALIDATE_OTP = Config.BASE_URL + 'users/validate_otp';
    public static LOGIN_WITH_PASSWORD = Config.BASE_URL + 'users/login_with_password';
    public static ORDER_ITEM = Config.BASE_URL + 'order/item';
    public static ORDER_CART_ITEMS = Config.BASE_URL + 'order/cart_items';
    public static ORDER_CART_ITEM_REMOVE = Config.BASE_URL + 'order/item_remove';
    public static ORDER_CART_ITEMS_CLEAR = Config.BASE_URL + 'order/items_clear';
    public static ORDER_CART_CHECKOUT = Config.DOMAIN_URL+"admin/index.php/api/order_post";
    public static CUSTOMER_FORM_URL = Config.DOMAIN_URL+"admin/index.php/dynamic_form_view";
    public static CHAT_ACCESS_URL = Config.DOMAIN_URL+"chat/chat_access.php";
    public static PAYMENT_INTENT_URL = Config.SERVICE_URL+"payment/send_payment_intent";
    public static PAYMENT_TRANSACTION_CHECK_URL = Config.SERVICE_URL+"payment/transaction_check";
    public static PAYMENT_DONE_CHECK_URL = Config.SERVICE_URL+"payment/is_done";
    public static PAYMENT_ON_DEVICE_URL = Config.SERVICE_URL+"payment/payu_on_device";
    public static APPLY_PROMO_CODE_URL = Config.SERVICE_URL+"coupon/apply";
    public static PAYMENT_SUCCESS_URL = "https://app.wazzup.co/pay/success.php";
    public static PAYMENT_FAILURE_URL = "https://app.wazzup.co/pay/failure.php";
    public static PAYMENT_MERCHANT_KEY = "7KEarj";

    //APP SETTINGS
    public static APP_FLAVOR = 'default';
    public static APP_DEVICE_ID = 'default';
    public static APP_PASSCODE = 'default123';
    // public static APP_FLAVOR = 'premier';
    // public static APP_DEVICE_ID = 'premier_default';
    // public static APP_PASSCODE = 'premier123'; 

    public static DEFAULT_LOGIN_ENABLED = '0';
    public static REQUEST_TYPE_LOGIN = 'login';
    public static APP_DEVICE_TYPE = 'web'; // Web
    public static APP_TYPE = 'web'; // Web
    public static RESPONSE_SUCCESS = "success";
    public static RESPONSE_ERROR = "Error";

    //ORDER SETTINGS
    public static POST_TO_CHAT_CUSTOMER_PAYMENT = "2";
    public static ON_DEVICE = "1";
    public static PAYMENT_INTENT = "2";
    public static COMBINED = "3";
    public static TRANSACTION_TYPE_ORDER = "order";
    //GCP::Wazzup Dashboard Map::API Key
    public static GOOGLE_MAP_API_KEY = "AIzaSyDXstZkgMhF8RuXmYtzykQaxL1iZzSW10w";

    //APP DEVICE TYPE CONSTANTS
    public static BREADCRUMBS_ADY_ECOMM = "Brands";
    public static BREADCRUMBS_ADY_DEFAULT = "Categories";
    public static CHANNEL_LABEL_ADY_ECOMM = "Categories";
    public static CHANNEL_LABEL_ADY_DEFAULT = "Items";
    public static SEARCH_PLACEHOLDER_ECOMM = "Brands";
    public static SEARCH_PLACEHOLDER_DEFAULT = "Categories";
    public static SEARCH_PLACEHOLDER_DEFAULT_SUB = "Sub Categories";

    //Toast Message
    public static TOAST_MSG_TIMEOUT = 3000;
    public static TOAST_MSG_POSITION_CLASS = "toast-custom-1";
}