import { BrowserModule} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {VgCoreModule} from 'videogular2/core';
import {VgControlsModule} from 'videogular2/controls';
import {VgOverlayPlayModule} from 'videogular2/overlay-play';
import {VgBufferingModule} from 'videogular2/buffering';

import { routes } from './router'; // implement router module
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { PlaylistsComponent } from './dashboard-home/playlists/playlists.component';
import { ResourceComponent } from './dashboard-home/resource/resource.component';
import { DetailsPageComponent } from './dashboard-home/details-page/details-page.component';
import { ConnectionComponent } from './connection/connection.component';
import { BannerScreenComponent } from './banner-screen/banner-screen.component';
import { AuthGuard } from './guard/auth.guard';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { PaymentComponent } from './payment/payment/payment.component';
import { SuccessComponent } from './payment/success/success.component';
import { FailureComponent } from './payment/failure/failure.component';
import { NgxGalleryModule } from 'ngx-gallery';
import { LoginComponent } from './login/login.component';
import { AppLayoutComponent } from './_layout/app-layout/app-layout.component';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';
import { SiteHeaderComponent } from './login/site-header/site-header.component';
import { SiteSliderComponent } from './login/site-slider/site-slider.component';
import { SiteFooterComponent } from './login/site-footer/site-footer.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { BrandsComponent } from './brands/brands.component';
import { RefreshComponent } from './refresh/refresh.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DashboardHomeComponent,
    PlaylistsComponent,
    ResourceComponent,
    DetailsPageComponent,
    ConnectionComponent,
    BannerScreenComponent,
    PaymentComponent,
    SuccessComponent,
    FailureComponent,
    LoginComponent,
    AppLayoutComponent,
    SiteLayoutComponent,
    SiteHeaderComponent,
    SiteSliderComponent,
    SiteFooterComponent,
    ContactComponent,
    PrivacyPolicyComponent,
    BrandsComponent,
    RefreshComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    ReactiveFormsModule,

    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule, AngularMultiSelectModule,
    
    ToastrModule.forRoot({
      // preventDuplicates: true
    }),
    BrowserAnimationsModule,
    NgxGalleryModule
  ],
  providers: [AuthGuard,CookieService],
  bootstrap: [AppComponent],
})
export class AppModule { 
  
}
