import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SiteLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    this.loadStylesheet("assets/site/css/bootstrap.min.css");
    this.loadStylesheet("assets/site/css/owl.carousel.min.css");
    this.loadStylesheet("assets/site/css/magnific-popup.css");
    this.loadStylesheet("assets/site/css/font-awesome.min.css");
    this.loadStylesheet("assets/site/css/themify-icons.css");
    this.loadStylesheet("assets/site/css/nice-select.css");
    this.loadStylesheet("assets/site/css/flaticon.css");
    this.loadStylesheet("assets/site/css/gijgo.css");
    this.loadStylesheet("assets/site/css/animate.min.css");
    this.loadStylesheet("assets/site/css/slick.css");
    this.loadStylesheet("assets/site/css/slicknav.css");
    this.loadStylesheet("assets/site/css/style.css");

    this.loadScript("assets/site/js/vendor/modernizr-3.5.0.min.js");
    this.loadScript("assets/site/js/vendor/jquery-1.12.4.min.js");
    this.loadScript("assets/site/js/popper.min.js");
    this.loadScript("assets/site/js/bootstrap.min.js");
    this.loadScript("assets/site/js/owl.carousel.min.js");
    this.loadScript("assets/site/js/isotope.pkgd.min.js");
    this.loadScript("assets/site/js/waypoints.min.js");
    this.loadScript("assets/site/js/imagesloaded.pkgd.min.js");
    this.loadScript("assets/site/js/scrollIt.js");
    this.loadScript("assets/site/js/jquery.scrollUp.min.js");
    this.loadScript("assets/site/js/wow.min.js");
    this.loadScript("assets/site/js/nice-select.min.js");
    this.loadScript("assets/site/js/jquery.slicknav.min.js");
    this.loadScript("assets/site/js/jquery.magnific-popup.min.js");
    this.loadScript("assets/site/js/plugins.js");
    this.loadScript("assets/site/js/gijgo.min.js");
    this.loadScript("assets/site/js/slick.min.js");

    this.loadScript("assets/site/js/contact.js");
    this.loadScript("assets/site/js/jquery.form.js");
    this.loadScript("assets/site/js/jquery.validate.min.js");

    this.loadScript("assets/site/js/main.js");
  }

  public loadStylesheet(url: string) {

    if (url) {

      const head = <HTMLHeadElement>document.head;
      const styles = document.createElement('link');
      styles.rel = 'stylesheet';
      styles.type = 'text/css';
      styles.media = 'screen';
      styles.href = url;
      head.appendChild(styles);
    }
  }

  public loadScript(url: string) {

    if (url) {

      const body = <HTMLDivElement>document.body;
      const script = document.createElement('script');
      script.innerHTML = '';
      script.src = url;
      script.async = false;
      script.defer = true;
      body.appendChild(script);
    }
  }
}
