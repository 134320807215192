import { Component, OnInit } from '@angular/core';
import { Config } from '../../config/config';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.loadScript("https://maps.googleapis.com/maps/api/js?key="+Config.GOOGLE_MAP_API_KEY+"&callback=initMap");
    this.loadScript("assets/site/js/contact.js");
    //
  }

  public loadScript(url: string) {

    if (url) {

      const body = <HTMLDivElement>document.body;
      const script = document.createElement('script');
      script.innerHTML = '';
      script.src = url;
      script.async = false;
      script.defer = true;
      body.appendChild(script);
    }
  }

}
