import { Routes } from '@angular/router';

import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { PlaylistsComponent } from './dashboard-home/playlists/playlists.component';
import { LoginComponent } from './login/login.component';
import { ResourceComponent } from './dashboard-home/resource/resource.component';
import { DetailsPageComponent } from './dashboard-home/details-page/details-page.component';
import { ConnectionComponent } from './connection/connection.component';
import { BannerScreenComponent } from './banner-screen/banner-screen.component';
import { PaymentComponent } from './payment/payment/payment.component';

import { AuthGuard } from './guard/auth.guard';
import { SuccessComponent } from './payment/success/success.component';
import { FailureComponent } from './payment/failure/failure.component';
import { AppLayoutComponent } from './_layout/app-layout/app-layout.component';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { BrandsComponent } from './brands/brands.component';
import { RefreshComponent } from './refresh/refresh.component';

export const routes: Routes = [

  // App routes goes here
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardHomeComponent, canActivate: [AuthGuard], pathMatch: 'full' },
      { path: 'category', component: DashboardHomeComponent, canActivate: [AuthGuard] },
      { path: 'category/:', component: DashboardHomeComponent, canActivate: [AuthGuard] },
      { path: 'category/:/:', component: PlaylistsComponent, canActivate: [AuthGuard] },
      { path: 'category/:/:/:', component: PlaylistsComponent, canActivate: [AuthGuard] },
      { path: 'category/:/:/:/:', component: ResourceComponent, canActivate: [AuthGuard] },
      { path: 'category/:/:/:/:/:', component: ResourceComponent, canActivate: [AuthGuard] },
      { path: 'resources', component: ResourceComponent, canActivate: [AuthGuard] },
      { path: 'payment', component: PaymentComponent, canActivate: [AuthGuard] },
      { path: 'payment/success', component: SuccessComponent, canActivate: [AuthGuard] },
      { path: 'payment/failure', component: FailureComponent, canActivate: [AuthGuard] },
      { path: 'category/:/:/:/:/:/:/:', component: DetailsPageComponent, canActivate: [AuthGuard] },
      { path: 'category/:/:/:/:/:/:/:/:', component: DetailsPageComponent, canActivate: [AuthGuard] },
      { path: 'banner', component: BannerScreenComponent, canActivate: [AuthGuard] },
      { path: 'error', component: ConnectionComponent, canActivate: [AuthGuard] },
    ]
  },

  // Site routes goes here 
  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent  },
      { path: 'contact', component: ContactComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'brands', component: BrandsComponent },
      { path: 'refresh', component: RefreshComponent },
    ]
  },

  // otherwise
  { path: '**', component: ConnectionComponent, canActivate: [AuthGuard] }
];