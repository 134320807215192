import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import { Config } from '../../../config/config';
import { ToastrService } from 'ngx-toastr';
import { ControlService } from '../../services/control.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-site-slider',
  templateUrl: './site-slider.component.html',
  styleUrls: ['./site-slider.component.css']
})
export class SiteSliderComponent implements OnInit {

  initialSettings;
  supported_login;
  otpResponse;
  placeholderStation: String;
  station: string;
  deviceId: string;
  lisenceKey: string;
  loginOtp;
  responseData;
  header_bar_color;
  header_title_color;
  isLicenceKeyHidden: boolean = true;
  isDeviceHidden: boolean = true;
  isChangeAppDeviceHidden: boolean = true;
  isLoginOtpButtonHidden: boolean = true;
  isHaveAccountHidden: boolean = true;
  isLoginButtonHidden: boolean = true;
  isSubmitCancelHidden: boolean = true;
  isOtpHidden: boolean = true;
  isOtpSubmitCancelHidden: boolean = true;
  isGoToDashboardHidden: boolean = true;
  isStationHidden: boolean = false;

  constructor(
    public appComponent: AppComponent,
    private apiService: ApiService,
    private toastr: ToastrService,
    public controlService: ControlService,
    private router: Router,
    private cookieService: CookieService,

  ) {

    this.getSupportLogin();
  }

  ngOnInit() {
  }
  getSupportLogin() {
    this.appComponent.getInitialSettings().subscribe(
      data => {
        this.initialSettings = data;
        this.supported_login = this.initialSettings.data.supported_login;
        if (this.cookieService.get(Config.COOKIE_NAME).length > 0) {
          this.isGoToDashboardHidden = false;
          this.isStationHidden = true;
        } else {
          this.setLoginForm();
        }
      }
    ), err => {
      console.log("error");
    }
  }

  setLoginForm() {

    this.isLicenceKeyHidden = true;
    this.isDeviceHidden = true;
    this.isChangeAppDeviceHidden = true;
    this.isLoginOtpButtonHidden = true;
    this.isHaveAccountHidden = true;
    this.isLoginButtonHidden = true;
    this.isSubmitCancelHidden = true;
    this.isOtpHidden = true;
    this.isOtpSubmitCancelHidden = true;
    this.isGoToDashboardHidden = true;
    this.isStationHidden = false;

    switch (this.supported_login) {
      case '0':

        this.placeholderStation = "Email/ Mobile/ ID";
        this.isLicenceKeyHidden = false;
        this.isChangeAppDeviceHidden = false;
        this.isLoginButtonHidden = false;
        break;
      case '1':

        this.placeholderStation = "Mobile";
        this.isLoginOtpButtonHidden = false;
        this.isHaveAccountHidden = false;
        this.isLoginButtonHidden = false;
        break;
      case '2':

        this.placeholderStation = "Mobile";
        this.isLicenceKeyHidden = false;
        this.isChangeAppDeviceHidden = false;
        this.isLoginButtonHidden = false;
        break;
      case '3':

        this.placeholderStation = "Email/ Mobile/ ID";
        this.isLoginOtpButtonHidden = false;
        this.isHaveAccountHidden = false;
        this.isLoginButtonHidden = false;
        break;
      case '4':

        this.placeholderStation = "Email";
        this.isLoginOtpButtonHidden = false;
        this.isHaveAccountHidden = false;
        this.isLoginButtonHidden = false;
        break;
      case '5':

        this.placeholderStation = "Email";
        this.isLicenceKeyHidden = false;
        this.isChangeAppDeviceHidden = false;
        this.isLoginButtonHidden = false;
        break;
      case '6':

        this.placeholderStation = "Email/ Mobile/ ID";
        this.isLoginOtpButtonHidden = false;
        this.isHaveAccountHidden = false;
        this.isLoginButtonHidden = false;
        break;
      case '7':

        this.placeholderStation = "Email/ Mobile";
        this.isLoginOtpButtonHidden = false;
        this.isHaveAccountHidden = false;
        this.isLoginButtonHidden = false;
        break;
      case '8':

        this.placeholderStation = "Email/ Mobile /ID";
        this.isLoginOtpButtonHidden = false;
        this.isHaveAccountHidden = false;
        this.isLoginButtonHidden = false;
        break;
      default:

        this.placeholderStation = "Mobile No.";
        this.isLoginOtpButtonHidden = false;
        this.isHaveAccountHidden = false;
        this.isLoginButtonHidden = false;
    }
  }

  onLoginWithOTP() {



    var station = this.station;


    // if there is mobile number
    const body = {
      device_type: Config.APP_DEVICE_TYPE,
      app_type: Config.APP_TYPE,
      app_flavor: Config.APP_FLAVOR,
      station: station
    };

    this.apiService.callWazzupV2Service(Config.REQUEST_OTP, body)
      .subscribe(data => {

        this.otpResponse = data;
        if (this.otpResponse.status == "success") {
          this.toastr.success(this.otpResponse.message, this.otpResponse.status, {
            timeOut: 3000,
            positionClass: 'toast-custom-1',
            progressBar: true,
            progressAnimation: 'increasing'
          });

          this.getOtpScreen();
        } else if (this.otpResponse.status == "error") {


          //toast mo registered mobile 
          this.toastr.error(this.otpResponse.message, this.otpResponse.status, {
            timeOut: 3000,
            positionClass: 'toast-custom-1',
            progressBar: true,
            progressAnimation: 'increasing'
          });
        }
      }), err => {
        console.log("error");
      }
  }

  getOtpScreen() {


    this.isOtpHidden = false;
    this.isOtpSubmitCancelHidden = false;
    this.isLoginOtpButtonHidden = true;
    this.isHaveAccountHidden = true;
    this.isLoginButtonHidden = true;
  }

  onValidateOtp() {

    var otp = this.loginOtp;

    const body = {
      app_type: Config.APP_TYPE,
      app_flavor: Config.APP_FLAVOR,
      otp: otp,
      station: this.station
    };

    this.apiService.callWazzupV2Service(Config.VALIDATE_OTP, body)
      .subscribe(
        data => {

          this.responseData = data;
          if (this.responseData.status == "success") {

            this.controlService.default_dynamic_form = this.responseData.data.default_dynamic_form;
            this.controlService.device_id = this.responseData.data.device_id;
            this.header_bar_color = this.responseData.data.player_bar_color;
            this.header_title_color = this.responseData.data.playlist_title_color;
            this.controlService.app_device_urls = this.responseData.data.app_device_urls;
            this.controlService.appDeviceType = this.responseData.data.app_device_type;
            this.setAppLocalStorage();

            this.createAppCookie();//create cookie with station name
            this.router.navigate(['/']);
          } else {

            this.toastr.error(this.responseData.message, this.responseData.status, {
              timeOut: 3000,
              positionClass: 'toast-custom-1',
              progressBar: true,
              progressAnimation: 'increasing'
            });
          }
        }
      )
  }

  setAppLocalStorage() {

    if (this.controlService.app_device_urls) {

      localStorage.setItem(Config.COOKIE_APP_DEVICE_URLS, JSON.stringify(this.controlService.app_device_urls));
    } else {

      localStorage.setItem(Config.COOKIE_APP_DEVICE_URLS, "");
    }
    if (this.responseData.data.home_page_enabled) {

      localStorage.setItem(Config.COOKIE_HOME_PAGE_ENABLED, this.responseData.data.home_page_enabled);
    } else {

      localStorage.setItem(Config.COOKIE_HOME_PAGE_ENABLED, "0");
    }
    if (this.responseData.data.logo) {

      console.log(this.responseData.data.logo);
      localStorage.setItem(Config.COOKIE_HOME_PAGE_LOGO, this.responseData.data.logo);
    } else {

      localStorage.setItem(Config.COOKIE_HOME_PAGE_LOGO, "");
    }
    if (this.responseData.data.banner) {

      localStorage.setItem(Config.COOKIE_HOME_PAGE_BANNER, this.responseData.data.banner);
    } else {

      localStorage.setItem(Config.COOKIE_HOME_PAGE_BANNER, "");
    }
    if (this.responseData.data.banner_video) {

      localStorage.setItem(Config.COOKIE_HOME_PAGE_BANNER_VIDEO, this.responseData.data.banner_video);
    } else {

      localStorage.setItem(Config.COOKIE_HOME_PAGE_BANNER_VIDEO, "");
    }
    if (this.responseData.data.payment_type) {

      localStorage.setItem(Config.COOKIE_ORDER_PAYMENT_TYPE, this.responseData.data.payment_type);
    } else {

      localStorage.setItem(Config.COOKIE_ORDER_PAYMENT_TYPE, "0");
    }
    if (this.responseData.data.player_resolution) {

      localStorage.setItem(Config.COOKIE_HOME_PAGE_PLAYER_RESOLUTION, this.responseData.data.player_resolution);
    } else {

      localStorage.setItem(Config.COOKIE_HOME_PAGE_PLAYER_RESOLUTION, "");
    }
    if (this.responseData.data.order_posting_type) {

      localStorage.setItem(Config.COOKIE_ORDER_POSTING_TYPE, this.responseData.data.order_posting_type);
    } else {

      localStorage.setItem(Config.COOKIE_ORDER_POSTING_TYPE, "0");
    }
    if (this.responseData.data.app_device_type) {

      localStorage.setItem(Config.COOKIE_APP_DEVICE_TYPE, this.responseData.data.app_device_type);
    } else {

      localStorage.setItem(Config.COOKIE_APP_DEVICE_TYPE, "0");
    }
    if (this.responseData.data.promo_code_enabled) {

      localStorage.setItem(Config.COOKIE_PROMO_CODE_ENABLED, this.responseData.data.promo_code_enabled);
    } else {

      localStorage.setItem(Config.COOKIE_PROMO_CODE_ENABLED, "0");
    }
  }

  createAppCookie() {


    this.cookieService.set(Config.COOKIE_NAME, this.station);
    this.cookieService.set(Config.COOKIE_DEVICE_ID, this.controlService.device_id);
    this.cookieService.set(Config.COOKIE_DYNAMIC_FORM_ID, this.controlService.default_dynamic_form);
    this.cookieService.set(Config.COOKIE_HEADER_BAR_COLOR, this.header_bar_color);
    this.cookieService.set(Config.COOKIE_HEADER_TITLE_COLOR, this.header_title_color);
  }

  onLogin() {


    this.isLoginOtpButtonHidden = true;
    this.isHaveAccountHidden = true;
    this.isLoginButtonHidden = true;
    this.isLicenceKeyHidden = false;
    this.isChangeAppDeviceHidden = false;
    this.isSubmitCancelHidden = false;
  }
  onChangeDevice() {
    if (this.isDeviceHidden) {

      this.isDeviceHidden = false;
    } else {

      this.isDeviceHidden = true;
    }
  }

  onLoginWithPassword() {



    var changeDevice = 0;

    if (!this.isChangeAppDeviceHidden) {
      changeDevice = 1;
    }
    var station = this.station;
    if (Config.DEFAULT_LOGIN_ENABLED == '1') {
      var deviceId = Config.APP_DEVICE_ID;
    } else {
      var deviceId = this.deviceId;
    }
    var licenseKey = this.lisenceKey;

    // if there is mobile number
    const body = {
      device_type: Config.APP_DEVICE_TYPE,
      app_type: Config.APP_TYPE,
      app_flavor: Config.APP_FLAVOR,
      station: station,
      device_id: deviceId,
      passcode: licenseKey,
      request_type: Config.REQUEST_TYPE_LOGIN,
      change_device: changeDevice
    };

    this.apiService.callWazzupV2Service(Config.LOGIN_WITH_PASSWORD, body)
      .subscribe(
        data => {
          this.responseData = data;

          if (this.responseData.status == "success") {


            this.controlService.default_dynamic_form = this.responseData.data.default_dynamic_form;
            this.controlService.device_id = this.responseData.data.device_id;

            this.deviceId = this.responseData.data.device_id;
            this.station = this.responseData.data.station_name;
            this.header_bar_color = this.responseData.data.player_bar_color;
            this.header_title_color = this.responseData.data.playlist_title_color;
            this.controlService.app_device_urls = this.responseData.data.app_device_urls;
            this.setAppLocalStorage();
            this.createAppCookie();//create cookie with station name
            this.router.navigate(['/']);
          } else {

            this.toastr.error(this.responseData.message, this.responseData.status, {
              timeOut: 3000,
              positionClass: 'toast-custom-1',
              progressBar: true,
              progressAnimation: 'increasing'
            });
          }
        }
      ), err => {
        console.log("error");
      }
  }

  onGoToDashboard() {
    if (this.cookieService.get(Config.COOKIE_NAME).length > 0) {

      this.router.navigate(['/']);
    } else {

      this.setLoginForm();
    }
  }
}
