import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../../config/config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ControlService } from '../../services/control.service';
import { BroadcastService } from '../../services/broadcast.service'


declare var $: any;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  private payment_type;
  private order_posting_type;
  private do_payment;
  private order_id;
  private outstanding_payment_id;
  private amount;
  private customer_id;
  private product_info;
  private transaction_id;
  private transaction_type;
  private responseData: any = {};
  private hash;
  private payIntentIntervalId;
  private payIntentReferenceId;
  private subscription;
  private countIsDoneCalled;

  constructor(private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    public controlService: ControlService,
    private broadcastService: BroadcastService
  ) {

    this.subscription = this.broadcastService.getMessage().subscribe(message => {
      
      if (message == "closeCustomerFormModal") {

        console.log("closeCustomerFormModal clicked");
      }
    });
  }

  ngOnInit() {

    var current_date = new Date();
    var milli_seconds = current_date.getTime();

    this.activatedRoute.queryParams.subscribe(params => {
      this.do_payment = (params["do_payment"] != undefined) ? params["do_payment"] : 0;
      this.order_id = (params["order_id"] != undefined) ? params["order_id"] : 0;
      this.outstanding_payment_id = (params["outstanding_payment_id"] != undefined) ? params["outstanding_payment_id"] : 0;
      this.amount = (params["amount"] != undefined) ? params["amount"] : 0;
      this.customer_id = (params["customer_id"] != undefined) ? params["customer_id"] : 0;
      this.product_info = (params["product_info"] != undefined) ? params["product_info"] : "";
      this.transaction_id = "o" + this.order_id + "c" + this.customer_id + "t" + milli_seconds;
      this.transaction_type = Config.TRANSACTION_TYPE_ORDER;

      localStorage.setItem("do_payment", this.do_payment);
      localStorage.setItem("order_id", this.order_id);
      localStorage.setItem("outstanding_payment_id", this.outstanding_payment_id);
      localStorage.setItem("amount", this.amount);
      localStorage.setItem("customer_id", this.customer_id);
      localStorage.setItem("productinfo", this.product_info);
      localStorage.setItem("transaction_id", this.transaction_id);
      localStorage.setItem("transaction_type", this.transaction_type);

      
    });

    this.order_posting_type = localStorage.getItem(Config.COOKIE_ORDER_POSTING_TYPE);
    this.payment_type = localStorage.getItem(Config.COOKIE_ORDER_PAYMENT_TYPE);
    
    if (this.order_posting_type == Config.POST_TO_CHAT_CUSTOMER_PAYMENT) {

      $('#payment_intent').show();
      //TODO: Temporarily disabled
      // if (this.payment_type == Config.ON_DEVICE) {

      //   
      //   this.paymentOnDevice();
      // } else if (this.payment_type == Config.PAYMENT_INTENT) {

      //   
      //   $('#payment_intent').show();
      // } else if (this.payment_type == Config.COMBINED) {

      //   
      //   $('#payment_combined').show();
      // } else {

      //   console.log("Payment Disabled");
      // }
    } else {

      console.log("Payment Disabled by order_posting_type");
    }
  }

  closeMe() {

    
    $('#customer_form_close_modal', parent.document).trigger('click');
  }

  showPaymentIntent() {

    $('#payment_combined').hide();
    $('#payment_intent').show();
  }

  sendPaymentIntent() {

    var email = $("#email").val();
    var mobile = $("#mobile").val();
    if (email == '' && mobile == '') {

      this.toastr.error("Error", "Atleast email or mobile should be specified to send Payment Intent.", {
        timeOut: 2000,
        positionClass: 'toast-custom-1',
        progressBar: true,
        progressAnimation: 'increasing'
      });
    } else {

      var sending_email_now = (email != "") ? "1" : "0";
      var send_sms = (mobile != "") ? "1" : "0";
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'

        })
      };

      let body = new HttpParams();
      body = body.set('txnid', this.transaction_id);
      body = body.set('amount', this.amount);
      body = body.set('productinfo', this.product_info);
      body = body.set('app_flavor', Config.APP_FLAVOR);
      body = body.set('firstname', "");
      body = body.set('email', email);
      body = body.set('phone', mobile);
      body = body.set('order_id', this.order_id);
      body = body.set('outstanding_payment_id', this.outstanding_payment_id);
      body = body.set('transaction_type', this.transaction_type);
      body = body.set('send_email_now', sending_email_now);
      body = body.set('send_sms', send_sms);

      

      return this.httpClient.post(Config.PAYMENT_INTENT_URL, body, httpOptions)
        .subscribe(
          data => {
            this.responseData = data;
            
            if (this.responseData.status == "success") {

              $('#payment_intent').hide();
              $('#payment_intent_result').show();
              $("#span_order_id").html(this.order_id);
              $("#span_transaction_id").html(this.transaction_id);
              if (this.responseData.reference_id) {

                if (this.payIntentIntervalId) {

                  clearInterval(this.payIntentIntervalId);
                }
                localStorage.setItem(Config.COOKIE_REPEAT_PAYMENT_IS_DONE, "1");
                this.countIsDoneCalled = 0;
                this.payIntentIntervalId = setInterval(() => { this.checkPaymentIntentStatus() }, 10000);
                this.payIntentReferenceId = this.responseData.reference_id;
              }
              this.toastr.success("", this.responseData.message, {
                timeOut: 2000,
                positionClass: 'toast-custom-1',
                progressBar: true,
                progressAnimation: 'increasing'
              });
            } else {
              this.toastr.error("", this.responseData.message, {
                timeOut: 2000,
                positionClass: 'toast-custom-1',
                progressBar: true,
                progressAnimation: 'increasing'
              });
            }

          },
          error => {
            this.toastr.error("", this.responseData.message, {
              timeOut: 2000,
              positionClass: 'toast-custom-1',
              progressBar: true,
              progressAnimation: 'increasing'
            });
          }
        );
    }
  }

  clickPaymentCompleted() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'

      })
    };

    let body = new HttpParams();
    body = body.set('txnid', this.transaction_id);
    body = body.set('app_flavor', Config.APP_FLAVOR);
    body = body.set('order_id', this.order_id);

    

    return this.httpClient.post(Config.PAYMENT_TRANSACTION_CHECK_URL, body, httpOptions)
      .subscribe(
        data => {
          this.responseData = data;
          
          if (this.responseData.status == "success") {

            $("#btn_payment_completed").hide();
            if (this.responseData.payment_status == "success") {

              $("#header_text").html("Transaction Successful");
              $("#img_waiting").hide();
              $("#img_success").show();
              $("#img_failed").hide();
              $("#payment_description").html(this.responseData.payment_message);
              this.stopPayIntentInterval();
            } else if (this.responseData.payment_status == "failed") {

              $("#header_text").html("Transaction Failed");
              $("#img_waiting").hide();
              $("#img_success").hide();
              $("#img_failed").show();
              $("#payment_description").html(this.responseData.payment_message);
              this.stopPayIntentInterval();
            } else {

              $("#header_text").html(this.responseData.status);
            }
          } else {
            this.toastr.error("", this.responseData.message, {
              timeOut: 2000,
              positionClass: 'toast-custom-1',
              progressBar: true,
              progressAnimation: 'increasing'
            });
          }

        },
        error => {
          this.toastr.error("", this.responseData.message, {
            timeOut: 2000,
            positionClass: 'toast-custom-1',
            progressBar: true,
            progressAnimation: 'increasing'
          });
        }
      );
  }

  paymentOnDevice() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'

      })
    };

    let body = new HttpParams();
    body = body.set('key', Config.PAYMENT_MERCHANT_KEY);
    body = body.set('txnid', this.transaction_id);
    body = body.set('amount', this.amount);
    body = body.set('productinfo', this.product_info);
    body = body.set('firstname', "");
    body = body.set('email', "");
    body = body.set('surl', Config.PAYMENT_SUCCESS_URL);
    body = body.set('furl', Config.PAYMENT_FAILURE_URL);
    body = body.set('udf1', this.order_id);
    body = body.set('udf2', this.customer_id);
    body = body.set('udf3', this.transaction_type);
    body = body.set('udf4', this.outstanding_payment_id);
    body = body.set('udf5', Config.APP_FLAVOR);

    

    return this.httpClient.post(Config.PAYMENT_ON_DEVICE_URL, body, httpOptions)
      .subscribe(
        data => {
          this.responseData = data;
          this.hash = this.responseData.hash;
          localStorage.setItem("hash", this.hash);
          $('#customer_form_close_modal', parent.document).trigger('click');
          $('#payment_on_device_form', parent.document).trigger('click');
        },
        error => {

        }
      );
  }

  checkPaymentIntentStatus() {

    this.countIsDoneCalled++;
    let maxNumberOfCalls = 60;
    if (this.countIsDoneCalled > maxNumberOfCalls) {

      this.stopPayIntentInterval();
    }
    let doRepeat = localStorage.getItem(Config.COOKIE_REPEAT_PAYMENT_IS_DONE);
    if (doRepeat != "1") {

      this.stopPayIntentInterval();
    }
    if (this.order_id && this.transaction_id && this.payIntentReferenceId) {

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'

        })
      };

      let body = new HttpParams();
      body = body.set('reference_id', this.payIntentReferenceId);
      body = body.set('transaction_id', this.transaction_id);
      body = body.set('order_id', this.order_id);
      body = body.set('app_flavor', Config.APP_FLAVOR);
      
      return this.httpClient.post(Config.PAYMENT_DONE_CHECK_URL, body, httpOptions)
        .subscribe(
          data => {
            this.responseData = data;
            
            if (this.responseData.status == "success") {

              if (this.responseData.is_done == "1") {

                this.stopPayIntentInterval();
                this.clickPaymentCompleted();
              }
            }
          },
          error => {
            console.log(error.message);
          }
        );
    }
  }

  stopPayIntentInterval() {

    localStorage.setItem(Config.COOKIE_REPEAT_PAYMENT_IS_DONE, "0");
    if (this.payIntentIntervalId) {

      clearInterval(this.payIntentIntervalId);
    }
  }

  ngOnDestroy() {

    clearInterval(this.payIntentIntervalId);
  }
}

